.test-component{
    width: 100%;
    min-height: 170px;
    height: auto;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
    padding: 16px;
}

.test-component2{
    width: 100%;
    height: 550px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
}

.test-component3{
    width: 100%;
    min-height: 130px;
    height: auto;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
}

.test-component4{
    width: 100%;
    min-height: 880px;
    height: auto;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
}

.test-component5{
    width: 100%;
    min-height: 260px;
    height: auto;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
    padding: 16px;
}

.dashboard-subtitle{
    margin-bottom: 0;
    color: #37474f;
    font-size: 1rem;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.1;
    position: relative;
    margin: 16px;
}

.dashboard-table .ant-table{
    width: 100% !important;
    height: 440px;
    font-family: 'DM Sans' !important;
    font-size: 14px;
    text-align: center !important;
    scroll-behavior: auto;
    overflow-x: scroll;
}

.dashboard-table .ant-table-thead > tr > th{
    background-color: #ecf0f5 !important;
    color: #37474f !important;
    font-family: 'DM Sans' !important;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
}

.dashboard-table .ant-table-cell > tr > th{
    font-family: 'DM Sans' !important;
    font-size: 14px;
    font-weight: 600;
    color: #37474f !important;
    
}

.dashboard-table .ant-pagination{
   margin-right: 16px !important;
}

.dashboard-table2 .ant-table{
    width: 100% !important;
    height: 770px;
    font-family: 'DM Sans' !important;
    font-size: 14px;
    text-align: center !important;
    scroll-behavior: auto;
    overflow-x: scroll;
}

.dashboard-table2 .ant-table-thead > tr > th{
    background-color: #ecf0f5 !important;
    color: #37474f !important;
    font-family: 'DM Sans' !important;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
}

.dashboard-table2 .ant-table-cell > tr > th{
    font-family: 'DM Sans' !important;
    font-size: 14px;
    font-weight: 600;
    color: #37474f !important;
    
}

.dashboard-table2 .ant-pagination{
   margin-right: 16px !important;
}