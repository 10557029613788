.titulo{
    font-size: 24px;
    margin-left: 0px;
    font-weight: bold;
}

.titulo2{
    font-size: 24px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: -2px;
}

.subtitulo{
    font-size: 15px;
    margin-left: 43px;
    font-weight: bold;
    margin-top: -15px;
}

.back-icon{
    margin-top: 13px;
}

.sync-button{
    width: 160px;
    position: absolute;
    right: 0;
    top: -40px;
}

.titulo-container{
    display: flex;
}

.tituloShiipment{
    font-size: 16px;
}

.children-table2{
   width: 100% !important;
}

.children-table3{
    width: 100% !important;
    margin-top: 20px;
 }

 .children-table3-container{
    overflow-x: scroll !important;
 }

.custom-filter{
    height: 10px;
    width: 200px;
}
.SearchOutlinedTMS{
    margin-top: 30px;
    margin-left: 10px;
}
.custom-filter2{
    width: 200px;
}

.home-screen-inner-container12-select{
    height: 600px;
    width: 850px;
    margin-top: 0px;
}
.home-screen-inner-container13-select{
    height: calc(100% - 110%);
    width: calc(100% - 110%);
    margin-top: 0px;
}
.mouse-row-enter{
    background-color: white;
}
.mouse-row-enter:hover{
    background-color: gray;
}