
.form-element{
    width: 22vw !important;
}

.form-element-with-button{
    width: 20vw !important;
}


.form-element2{
    margin-top: 10px !important;
}
.ExcelGeneratorCabecera{
    margin-left: 750px;
}
.ExcelGeneratorLinea{
    margin-left: 10px;
}
.asterisco{
    color: red;
}

.upload-button {
    width: 300px;
    height: 200px;
    background-color: #D10505;
    border-color: #D10505;
    box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.25);
}

.upload-button:hover {
    background-color: #aa0404;
    border-color: #aa0404;
}
.Info-Geo-Clouser{
    margin-top: -40px;
    width: 300px;
    color: red;
}