.ant-btn-background-ghost.ant-btn-primary{
    border-color: transparent;
    color: black;
    width: 150px;
}

.ant-btn-background-ghost.ant-btn-primary:hover, .ant-btn-background-ghost.ant-btn-primary:focus{
    border-color: var(--primaryColor);
    color: var(--primaryColor);
    width: 150px;
}

.children-inner-col-button.ant-btn-primary{
    box-shadow: none;
}

.children-inner-col-button{
    width: 180px;
    height: 180px;
    border-radius: 30px;
}

.children-inner-col-box{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.children-inner-col-icon{
    font-size: 30px;
    margin-left: Calc(50% - 30px);
    padding-top: 15px;
    background-color: var(--primaryColor);
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding-left: 14px;
    padding-bottom: 15px;
}

.children-inner-col-label p{
    text-align: center;
    width: 100%;
    max-width: 180px;
    font-weight: 600;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: -10px;
}

@media screen and (max-width: 1200px) {
    .children-inner-col-button{
        width: 135px;
        height: 150px;
        border-radius: 30px;
        
    }

    .children-inner-col-label p{
        text-align: center;
        width: 100%;
        /* max-width: 135px; */
        font-weight: 400;
        font-size: 12px;
        margin-top: 12px;
        /* margin-bottom: -10px; */
    }

    .children-inner-col-icon{
        font-size: 25px;
        margin-left: Calc(50% - 25px);
        padding-top: 13px;
        
        background-color: var(--primaryColor);
        color: white;
        width: 50px;
        height: 50px;
        border-radius: 30px;
    }
}

.footer_version{
    color: #000;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
}