.basic-kpi-value{
    color:#37474f;
    font-weight: 600;
    font-size: 1.75rem;
    margin-bottom: 0px;
    padding-top: 0px;
}

.basic-kpi-label{
    color: #868e96!important;
    font-size: 13px;
    margin-bottom: 0px;
    padding-top: 0px;
}

.basic-kpi-graph{
    margin-top: 10px !important;
    
}

.basic-kpi-graph-line {
    width: 320px !important; 
    height: 80px !important;
}

.recharts-wrapper .basic-kpi-graph{
    width: 320px !important; 
    height: 80px !important;
}