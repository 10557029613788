body{
  background-color: #ecf0f5;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.sider-menu{
  overflow: 'auto';
  height: '100vh';
  position: 'fixed';
  left: 0;
  top: 0;
  bottom: 0;
}

.ant-layout-sider{
  background: #fff;
}

.main-container{
  width: 100vw;
  height: 100vh;
  overflow-y:overlay
}

/* .site-layout{
  width: 100%;
  height: 100%;
} */

.site-layout-background {
  background: #fff;
  padding: 0;
}

.site-color{
  background-color: var(--primaryColor);
}

.bg-color{
  background-color: #ecf0f5;
}

.site-header{
  /* position: fixed; */
  margin-top: -65px;
  z-index: 100;
  width: 100%;
  height: 56px;
  padding-left: 60px;
}

.site-title{
  /* color: white; */
  font-size: 25px;
  font-weight: 500;
  letter-spacing: -0.09rem;
  /* margin-top: -11px; */
}

.site-daterange{
  margin-top: -6px;
}

.site-daterange-tms{
  position: fixed;
  width: 250px;
  right: 140px;
  top: 112px;
  z-index: 1;
}

.site-logo-container{
  position:relative;
  margin-top: 4px;
  height: 58px;
  min-width: 200px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  transition: all 0.3s;
}

.site-logo-container-collapsed{
  position:relative;
  height: 56px;
  max-width: 80px;
  min-width: 70px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  transition: all 0.3s;
}

.site-logo{
  display:block;
  margin:auto;
  height: auto;
  max-width: 180px;
}

.site-logo-collapsed{
  display:block;
  margin:auto;
  height: auto;
  max-width: 60px;
  width: 60px;
}

.site-children{
  top: 0px;
  bottom: 0px !important;
  right: 0px;
  z-index: 0;
}

.collapsed2 > .site-children{
  left: 0px;
}

.hide-element{
  display: none;
}

.children-inner-container{
  padding: 20px;
}

.children-inner-container-with-bg{
  margin: 20px;
  padding: 20px;
  height: calc(100vh - 96px);
  border-radius: 4px;
  box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
  background-color: white;
  overflow-y: scroll;
}

.children-inner-button-refresh{
  margin-top: 10px;
  right: 20px !important; 
  position: absolute;
}
.children-inner-button-refresh{
  margin-top: 5px;
  margin-right: 5px;
}

.children-inner-row{
  margin-top: 20px;
  margin-bottom: 40px;
}

.children-inner-col{
  display: flex;
  flex-direction: column;
}

.children-inner-button{
  border-radius: 4px;
  font-weight: 600;
  color: white;
  align-self: flex-end;
}

.children-modal .ant-modal-content{
  border-radius: 4px !important;
}

.collapsed2 > .site-children .children-table .ant-table{
  width: calc(100vw - 80px) !important;
}

.children-table .ant-table{
  width: calc(100vw - 310px) !important;
  height: calc(100vh - 280px);
  font-family: 'DM Sans' !important;
  font-size: 14px;
  text-align: center !important;
  scroll-behavior: auto;
  overflow-x: scroll;
  border-radius: 4px;
}

.children-table .ant-table-thead > tr > th{
  background-color: #ecf0f5 !important;
  color: #37474f !important;
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  margin-left: 36px;
  margin-right: 36px;
}

.children-table .ant-table-cell > tr > th{
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  color: #37474f !important;
}

.children-table .ant-pagination{
 margin-right: 16px !important;
}

.children-table-2 .ant-table{
  width: calc(100vw - 310px) !important;
  height: calc(100vh - 410px);
  font-family: 'DM Sans' !important;
  font-size: 14px;
  text-align: center !important;
  scroll-behavior: auto;
  overflow-x: scroll;
  border-radius: 4px;
}

.children-table-2 .ant-table-thead > tr > th{
  background-color: #ecf0f5 !important;
  color: #37474f !important;
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  margin-left: 36px;
  margin-right: 36px;
}

.children-table-2 .ant-table-cell > tr > th{
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  color: #37474f !important;
}

.children-table-2 .ant-pagination{
 margin-right: 16px !important;
}

.children-steps{
  margin: 8px;
  padding: 8px;
}


 .children-table-3 .ant-table{
   width: calc(30vw - 80px) !important;
   height: calc(65vh - 180px);
   font-family: 'DM Sans' !important;
   font-size: 14px;
   text-align: center !important;
   scroll-behavior: auto;
   overflow-x: scroll;
   border-radius: 4px;
   padding-left: 30px;
 }

.children-table-101{
  width: calc(50vw - 80px) !important;
  height: 530px;
  font-family: 'DM Sans' !important;
  font-size: 14px;
  text-align: center !important;
  scroll-behavior: auto;
  overflow-x: scroll;
  border-radius: 4px;
 }
 .children-table-100{
  width: calc(30vw - 80px) !important;
  height: 530px;
  font-family: 'DM Sans' !important;
  font-size: 14px;
  text-align: center !important;
  scroll-behavior: auto;
  overflow-x: scroll;
  border-radius: 4px;
 }
 .children-table-3 .ant-table-thead > tr > th{
   background-color: #ecf0f5 !important;
   color: #37474f !important;
   font-family: 'DM Sans' !important;
   font-size: 14px;
   font-weight: 600;
   text-transform: uppercase;
   text-align: left;
   margin-left: 36px;
   margin-right: 36px;
 }
 
 .children-table-3 .ant-table-cell > tr > th{
   font-family: 'DM Sans' !important;
   font-size: 14px;
   font-weight: 600;
   color: #37474f !important;
 }
 
 .children-table-3 .ant-pagination{
  margin-right: 16px !important;
 }
 



 .children-table-4 .ant-table{
  width: calc(100% -0px) !important;
  height: calc(100% - 0px);
  font-family: 'DM Sans' !important;
  font-size: 14px;
  text-align: center !important;
  scroll-behavior: auto;
  overflow-x: scroll;
  border-radius: 4px;
}

.children-table-4 .ant-table-thead > tr > th{
  background-color: #ecf0f5 !important;
  color: #37474f !important;
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  margin-left: 36px;
  margin-right: 36px;
}

.children-table-4 .ant-table-cell > tr > th{
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  color: #37474f !important;
}

.children-table-4 .ant-pagination{
 margin-right: 16px !important;
}



.children-table-5 .ant-table{
  width: fit-content !important;
  height: calc(100% - 0px);
  max-height: 300px;
  font-family: 'DM Sans' !important;
  font-size: 14px;
  text-align: center !important;
  scroll-behavior: auto;
  overflow-x: scroll;
  border-radius: 4px;
}

.children-table-5 .ant-table-thead > tr > th{
  background-color: #ecf0f5 !important;
  color: #37474f !important;
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  margin-left: 36px;
  margin-right: 36px;
}

.children-table-5 .ant-table-cell > tr > th{
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  color: #37474f !important;
}

.children-table-5 .ant-pagination{
 margin-right: 16px !important;
}

.children-table-6 .ant-table{
  width: fit-content;
  height: fit-content;
  font-family: 'DM Sans' !important;
  font-size: 14px;
  text-align: center !important;
  border-radius: 4px;
}

.children-table-6-container{
  scroll-behavior: smooth;
  overflow: scroll;
  width: calc(100vw - 310px) !important;
  height: calc(100vh - 310px);
  margin-bottom: 14px;
}
.DescargaFormatos{
  left: 78%;
}

.children-table-6 .ant-table-thead > tr > th{
  background-color: #ecf0f5 !important;
  color: #37474f !important;
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  margin-left: 36px;
  margin-right: 36px;
}

.children-table-6 .ant-table-cell > tr > th{
  font-family: 'DM Sans' !important;
  font-size: 14px;
  font-weight: 600;
  color: #37474f !important;
 
}

.children-table-6 .ant-pagination{
 margin-right: 16px !important;
}

.report-indicator-container{
  width:fit-content;
  padding: 0px;
  margin: 0px;
  align-self: flex-end;
}

.report-indicator-value{
  padding: 0px;
  margin: 0px;
  margin-left: 8px;
  font-size: 20px;
  font-weight: 700;
}

.report-indicator-label{
  padding: 0px;
  margin: 0px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
}

.report-indicator-icon{
  height: 52px;
  width: 52px;
  padding: 14px;
  color: white;
  font-size: 24px;
  border-radius: 30px;
}

.ant-layout-sider-children{
  border-top-right-radius: 6px !important;
  padding-top: 0px;
}

/* .site-options{
  height: 48px;
  padding-top: 17px;
  margin-left: 250px;
  transition: all 0.3s;
}

.site-options-collapsed{
  height: 48px;
  padding-top: 17px;
  margin-left: 90px;
  transition: all 0.3s;
} */

.site-options-icon{
  font-size: 18px;
  color: black !important;
}

.site-options-icon-container-left{
  text-align: left;
}

.site-options-icon-container-right{
  text-align: center;
}
.site-options-icon-container-right1{
  margin-left: -300px;
}

.main-menu{
  padding: 16px;
}

.search-component{
  background-color: white;
  position: relative;
  z-index: 101;
  padding-left: 13px;
  /* padding-top: 13px; */
  width: 100%;
  height: 62px;
  box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
  margin-top: -65px;
}
.Editar_Orden_HeaderUploadScreen{
  margin-top: 10px;
  right: 60px !important; 
  position: absolute;
}
.Editar_Orden_HeaderUploadScreen{
  margin-top: 5px;
  margin-right: 5px;
}
.Crear_Usuario_HeaderUploadScreen{
  margin-left: 8px;
  height:30px;
}

@media screen and (max-width: 870px) {
  .site-title{
    display: none;
  }
}