.scheduling-close-dialog {
    width: 600px;
    height: 600px;
    background-color: #1A1A1A;
}

.scheduling-close-dialog-title {

    color: #1A1A1A !important;

}

.scheduling-close-dialog-actions {
    text-transform: capitalize;
    color: #1A1A1A !important;

}

.scheduling-close-dialog-content {
    font-size: 13px;
    color: #1A1A1A !important;

}
.identificationVerification{
    font-size: 10px;
    color: red;
}
.emailVerification{
    font-size: 10px;
    color: red;
}
.textField{
    width: 317px;
    height: -60px;
}