.standard-kpi-value{
    width: 100%;
    text-align: center;
    color:#37474f;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 0px;
    padding-top: 0px;
}


.standard-kpi-label{
    width: 100%;
    text-align: center;
    color: #868e96!important;
    font-size: 13px;
    margin-bottom: 0px;
    padding-top: 0px;
}

.standard-kpi-value2{
    width: 100%;
    text-align: center;
    color:#37474f;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0px;
    padding-top: 0px;
    margin-top: 10px;
}

.standard-kpi-label2{
    width: 100%;
    text-align: center;
    color: #868e96!important;
    font-size: 14px;
    margin-bottom: 0px;
    padding-top: 0px;
    margin-top: 4px;
}

.standard-kpi-graph{
    margin-top: 10px !important;
    
}

.standard-kpi-graph-line {
    width: 320px !important; 
    height: 80px !important;
}

.recharts-wrapper .standard-kpi-graph{
    width: 320px !important; 
    height: 80px !important;
}

.color-green{
    color: rgb(156, 204, 101);
}

.color-red{
    color: rgb(255, 82, 82);
}